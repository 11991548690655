
import { defineComponent, defineAsyncComponent, PropType } from "vue";
import DropdownMenuIcon from "../icons/RightArrowIcon.vue";
import QuestionMarkIcon from "../icons/QuestionMarkIcon.vue";
import { changeCase } from "../../helpers";
import { ElementE, DropdownTypeE } from '../../enums';
import { IShowDay, IShowVariant } from "../edittabs/constants";

export default defineComponent({
  name: "DropdownMenu",
  components: { DropdownMenuIcon, QuestionMarkIcon },
  props: {
    label: { type: String, required: true },
    defaultSelect: { type: String, default: "Select value" },
    values: {
      type: Array as () => (IShowDay | IShowVariant)[],
      default: () => [] as (IShowDay | IShowVariant)[],
    },
    isChangeCase: { type: Boolean, default: true },
    type: {
      type: String as PropType<DropdownTypeE>,
      default: DropdownTypeE.TEXT,
      validator: (value: string) => Object.values(DropdownTypeE).includes(value as DropdownTypeE)
    },
  },
  emits: ['select'],
  data() {
    return {
      isOptionsListVisible: false,
      selected: {
        id: '',
        name: this.defaultSelect,
      } as IShowDay | IShowVariant,
      showTooltipTxt: false,
      tooltipTxt:
        "Select the type of information shown in each cell of the matrix, such as available rooms, prices, or text.",

      changeCase: changeCase,
      ElementE: ElementE,
      DropdownTypeE,
    };
  },
  methods: {
    clickOutside() {
      this.isOptionsListVisible = false;
    },
    handleSelect(item: IShowDay | IShowVariant) {
      this.selected = item;
      this.$emit('select', item.id);
    },
    getAsyncComponent(name: string | number) {
      return defineAsyncComponent(() => import(`../icons/${name}.vue`));
    },
  },
});
