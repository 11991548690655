import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-6 w-[650px] p-6 rounded-lg bg-white shadow-default" }
const _hoisted_2 = { class: "flex gap-4" }
const _hoisted_3 = { class: "error flex justify-center items-center flex-shrink-0 w-12 h-12 rounded-sm" }
const _hoisted_4 = { class: "modal-heading" }
const _hoisted_5 = { class: "modal-content mt-1" }
const _hoisted_6 = { class: "flex gap-4 self-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorIcon = _resolveComponent("ErrorIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ErrorIcon)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.changeCase('delete widget', _ctx.ElementE.TITLE)), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.changeCase('are you sure you want to delete', _ctx.ElementE.SUBTITLE)) + " \"" + _toDisplayString(_ctx.title) + "\"? ", 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        class: "btn-secondary is-btn-size-default",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, _toDisplayString(_ctx.changeCase('cancel', _ctx.ElementE.BUTTON)), 1),
      _createElementVNode("button", {
        class: "btn-primary is-btn-size-default",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete', _ctx.widgetId)))
      }, _toDisplayString(_ctx.changeCase('delete', _ctx.ElementE.BUTTON)), 1)
    ])
  ]))
}