
import { defineComponent, ref } from "vue";
import {HtmlEvent} from "@/interfaces/interfaces";
import { changeCase } from "../helpers";
import { ElementE } from '../enums';

export default defineComponent({
  name: "SelectColor",
  props: {
    id: { type: String, required: true, default: "" },
    label: { type: String, required: true, default: "" },
    value: { type: String, required: true, default: "#ffffff" },
    isChangeCase: { type: Boolean, default: true },
  },
  mounted() {
    this.val = this.value;
  },
  watch: {
    value: function (nvalue) {
      this.val = nvalue
      this.backColor = nvalue;
    }
  },
  methods: {
    saveInput(e: HtmlEvent){
      this.$emit("colors", {
        id: e.target.id,
        color: e.target.value
      });
    }
  },
  data(){
    return {
      val: '#ffffff',

      changeCase: changeCase,
      ElementE: ElementE,
    }
  },
  emits: ["colors"],

  setup(_, { emit }) {
    const backColor = ref<string>("");

    const pickColor = (e: Event) => {
      backColor.value = (e.target as HTMLInputElement).value;
      let parentId = (e.target as HTMLInputElement).getAttribute('parentId');

      emit("colors", {
        id: parentId,
        color: backColor.value
      });
    };

    return { backColor, pickColor };
  },
});
