<template>
  <div class="base-input">
    <label
      class="label-primary"
      :for="id"
    >
      {{ label }}
    </label>
    <input
      ref="input"
      :id="id"
      v-model="model"
      :type="type"
      :placeholder="placeholder"
      :min="min"
      class="input-primary"
      :class="{
        'hide-default-arrows': type === 'number',
      }"
    />

    <!-- Custom Up and Down arrows for number Input -->
    <button
      v-if="type === 'number'"
      class="absolute right-3.5 top-3.5"
      @click="increaseValue"
    >
      <ArrowBlackIcon class="rotate-180" />
    </button>
    <button
      v-if="type === 'number'"
      class="absolute right-3.5 bottom-3.5"
      @click="decreaseValue"
    >
      <ArrowBlackIcon />
    </button>
  </div>
</template>

<script>
import ArrowBlackIcon from "@/components/icons/ArrowBlackIcon.vue";

export default {
  name: "BaseInput",
  components: {
    ArrowBlackIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    increaseValue() {
      this.model++;
    },
    decreaseValue() {
      if (this.model === this.min) return;
      this.model--;
    },
  },
};
</script>

<style scope>
.base-input {
  @apply relative text-neutral-125;
}

.hide-default-arrows {
  @apply [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none;
}
</style>
