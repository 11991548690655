<template>
  <div class="mb-6">
    <div class="tab-wrapper-primary">
      <div v-for="p in principals" :key="p.id">
        <div v-if="p.id == principal_id" class="tab-heading-primary underline flex">
          <a
            :href="`https://${p.principal_shortname}.resrequest.com/`"
            target="_blank"
            class="opacity-80 hover:opacity-100 flex items-center"
          >
            {{ changeCase(p.name, ElementE.TITLE) }}
            <LinkOutIcon
              class="pl-2"
            />
          </a>
        </div>
      </div>
      <div class="p-6">
        <SourceMenu
          :key="principal_id"
          :sources="sourceArray"
          tab="propery"
          @input="sourceUpdate"
          label="Create Booking with Source Code"
          :additional="principal_id"
          defaultSelect="Not specified"
          :values="sources"
        />

        <div class="h-px my-4 border border-dashed border-gray-300"></div>

        <p class="mb-4 text-sm font-medium">Select the properties for the widget</p>

        <ul>
          <li
            v-for="principal in formattedPrincipals"
            :key="principal.id"
          >
            <ul
              v-if="principal.id == principal_id"
              class="flex flex-col gap-4"
            >
              <li
                v-for="property in principal.properties"
                :key="property.id"
                class="flex gap-2.5"
              >
                <TwinSelect
                  class="flex-1"
                  :title="property.name"
                  :id="property.id"
                  :widgets="widget"
                  :principalId="principal.id"
                  :principals="principals"
                  @updateWidget="updateWidget"
                />
                <BaseInput
                  :id="`${property.id}-lead-time`"
                  :value="property.lead_time"
                  label="Booking lead time (Days)"
                  type="number"
                  :min="0"
                  @update:value="updateLeadTime($event, principal.id, property.id)"
                />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TwinSelect from "@/components/inputs/TwinSelect.vue";
import SourceMenu from "@/components/inputs/SourceMenu";
import LinkOutIcon from "../icons/LinkOutIcon.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import { changeCase } from "../../helpers";
import { ElementE } from '../../enums'

export default {
  name: "PropertyBlock",
  emits: ["updateWidget"],
  components: {
    TwinSelect,
    SourceMenu,
    LinkOutIcon,
    BaseInput,
  },
  props: {
    sources: {
      type: Object,
    },
    principals: {
      type: Object,
    },
    principal_id: {
      type: String,
    },
    widgets: {
      type: Object,
    },
  },
  data() {
    return {
      widget: {},
      sourceArray: {},

      changeCase: changeCase,
      ElementE: ElementE,
    };
  },
  computed: {
    formattedPrincipals() {
      return this.principals.map((principal) => {
        return {
          ...principal,
          properties: principal.properties.map((property) => {
            return {
              ...property,
              lead_time: this.widgets.config.principals
                .find(({ id }) => id === principal.id)?.properties
                .find(({ id }) => id === property.id)?.lead_time || 0,
            }
          })
        }
      });
    },
  },
  methods: {
    sourceUpdate(ids){
      const idsArray = ids.split("|");
      const sourceId = idsArray[0];
      const principalId = idsArray[1]

      if(this.widget.config.principal_source_maps === undefined){
        this.widget.config.principal_source_maps = [];
      }

      if(this.widget.config.principal_source_maps.length !== 0){

        this.widget.config.principal_source_maps =
          this.widget.config.principal_source_maps.filter((item) => item.principal_id !== principalId)

        this.widget.config.principal_source_maps.push(
          {
            "principal_id": principalId,
            "source_id": sourceId
          }
        )

      }else{
        this.widget.config.principal_source_maps.push(
          {
            "principal_id": principalId,
            "source_id": sourceId
          }
        )
      }

      this.updateWidget();
    },

    updateWidget() {
      this.$emit("updateWidget", this.widget);
    },

    updateLeadTime(value, principalId, propertyId) {
      const principal = this.widget.config.principals.find(({ id }) => id === principalId);

      if (!principal) return;
      const property = principal.properties.find(({ id }) => id === propertyId);

      if (!property) return;
      property.lead_time = value;

      this.updateWidget();
    },
  },
  mounted() {
    this.widget = this.widgets;
    this.sourceArray = this.widget.config.principal_source_maps;
  },
  watch: {
    widgets: function (widgets) {
      this.widget = widgets;
      if(this.widget.config !== undefined && this.widget.config.principal_source_maps !== undefined){
        this.sourceArray = this.widget.config.principal_source_maps;
      }
    },
  },
};
</script>
