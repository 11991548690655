export interface IShowDay {
    name: number;
    id: number;
}

export interface IShowVariant {
    name: string;
    id: string;
}

export const showDays: IShowDay[] = [
    { name: 5, id: 5 },
    { name: 7, id: 7 },
    { name: 10, id: 10 },
    { name: 14, id: 14 },
];

export const showVariant: IShowVariant[] = [
    { name: "Show Available Text", id: "availableText" },
    { name: "Show Prices", id: "rates" },
    { name: "Show Rooms Count", id: "availability" },
];

export const iconsList: IShowVariant[] = [
    {
        name: 'PriceTag',
        id: "PriceTag",
    },
    {
        name: 'PlusIcon',
        id: "PlusIcon",
    },
    {
        name: 'StarBlackIcon',
        id: "StarBlackIcon",
    },
    {
        name: 'AdultIcon',
        id: "AdultIcon",
    },
    {
        name: 'AdultsIcon',
        id: "AdultsIcon",
    },
    {
        name: 'ArrowBlackIcon',
        id: "ArrowBlackIcon",
    },
    {
        name: 'CalendarIcon',
        id: "CalendarIcon",
    },
    {
        name: 'ChildIcon',
        id: "ChildIcon",
    },
    {
        name: 'EmailIcon',
        id: "EmailIcon",
    },
    {
        name: 'EyeIcon',
        id: "EyeIcon",
    },
    {
        name: 'LinkIcon',
        id: "LinkIcon",
    },
    {
        name: 'ListIcon',
        id: "ListIcon",
    },
    {
        name: 'MinusIcon',
        id: "MinusIcon",
    },
];
